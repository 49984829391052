import axios from "axios";

export const patchResetPassword = async (body) => {
    try {
        const res = await axios({
            method: "PATCH",
            url: `https://nodepune-cryptobot.mobiloitte.com/api/v1/user/resetPassword`,
            data: body,
            headers: { token: localStorage.getItem("token") }
        });

        console.log("Reset-Password : ", res);
        return res.data.result;
    } catch (error) {
        console.log(error);
    }
};



