// // Base URL
// let baseurl="https://dexwin-api.herokuapp.com";
let baseurl ="https://nodepune-cryptobot.mobiloitte.com/api/v1";
const apiConfig = {

    serverIPAddress: `${baseurl}/wallet/serverIPAddress`,
    verifyOTPSMS: `${baseurl}/user/verifyOTP`,
    
}

export default apiConfig;