import { Container, Box, Typography, Grid } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { BsEnvelope } from "react-icons/bs";
import { FiPhone } from "react-icons/fi";
const useStyles = makeStyles((theme) => ({
 
  img: {

    paddingRight: "10px",
  },
  main: {

    padding: "50px 125px",
    [theme.breakpoints.down("xs")]:{

        padding: "50px 0px 50px 0px",
    },
    borderBottom: "1px solid #CECECE",
    "& a": {
      textDecoration: "none",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "19px",
      color: "#4E547B",
    },
  },
  submain: {
    padding: "50px 50px 40px 50px",
    [theme.breakpoints.down("xs")]:{

        padding: "50px 0px 50px 0px",
    },
    "& a":{

      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "19px",
      color: "rgba(65, 83, 199, 1)",
      
    },
    cursor:"pointer",
    "& h5": {
      textDecoration: "none",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "19px",
      color: "#FFFFFF",
    },
  },
  copy: {

    padding: "0px 10px 10px 10px",
    textAlign: "center",
    "& h5": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "12px",
      lineHeight: "19px",
      color: "#FFFFFF",
    },
  },
  footerSection1: {

  //  borderTop: "2px solid #CECECE",
  boxShadow:"0px 0px 4px 2px  #CECECE",
  background:"transparent"

  },
}));

export default function Footer2() {
  const classes = useStyles();
  return (
    <Box className={classes.footerSection1} >
      <Container>
        <Box >
          <Box pt={4}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src="images/warriorpattner.png" width="160px" height="55px" alt="" className={classes.img} />
       
          </Box>
          {/* <Box style={{display:"flex" , justifyContent:"space-around", alignItems:"center",padding: "20px 150px 20px 150px"}}>
            <Link>Home</Link>
            <Link>Supported Exchanges</Link>
            <Link>Supported Cryptocurrencies</Link>
            <Link>Arbitrage Trading Strategies</Link>
            <Link>Founding Team</Link>
        </Box> */}
          <Box className={classes.main}>
            <Grid container spacing={3} align="center">
              <Grid item xs={12} sm={6} md={4} lg={1}>
                <Link>Home</Link>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Link>Supported Exchanges</Link>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Link>Supported Cryptocurrencies</Link>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Link>Arbitrage Trading Strategies</Link>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Link>Founding Team</Link>
             
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Box className={classes.submain}>
              <Grid
                container
                spacing={3}
                align="center"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Grid item>
                  <Box style={{ display: "flex" }}>
                    <BsEnvelope
                      style={{ color:"#4E547B",borderRadius:'100px',width:'30px' }}
                    />
                     <Typography variant="h5" style={{ color: "#4E547B",}}>
                 Email : 
                  </Typography>
                    <Link     style={{ color: "#4E547B",}}>  info@warrior-partners.com</Link>
                  </Box>
                </Grid>
                <Grid item>
                  <Box style={{ display: "flex",color:"#4E547B" }}>
                    <FiPhone
                      style={{ borderRadius:'100px',color: "#4E547B",width:'30px' }}
                    />
                    <Typography variant="h5" style={{ color: "#4E547B",}}> Contact Us : Phone :+1 415 699 6465</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box className={classes.copy}>
              <Typography variant="h5" style={{ color: "#4E547B",}}>
                Copyright © 2022 LIMITED, ALL RIGHTS RESERVED
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}