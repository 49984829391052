import { Box, Container, Typography } from "@material-ui/core";
import React from "react";
import TopBar from "src/layouts/LoginLayout1/LoginTopBar.js";
import Footer from "src/layouts/LoginLayout1/Footer1.js";

export default function Index() {
  return (
  <>
    <TopBar />

    <Box>
      <Container>
        <Box mt={8} mb={5}>
          <Typography variant="h6">Privacy Policy</Typography>
        </Box>
        <Box style={{paddingBottom:"8rem"}}>
          <Typography variant="body1" style={{color:"#4D4D4D",fontSize:"16px"}}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam
            facilisis risus odio id amet leo. Justo, tortor purus varius non ut
            ullamcorper donec fringilla in. Imperdiet posuere dolor in tempor
            amet est. Sed nunc metus, nisi, sit cursus sagittis nisi,
            condimentum fringilla. Dictum ut nunc, rhoncus quis ornare eget
            facilisis facilisi pellentesque. Amet, aliquet orci, ut faucibus. In
            viverra amet quisque sit. Vulputate sapien sed purus dui viverra.
            Imperdiet ut adipiscing et, lorem amet suspendisse mi. Id consequat
            aliquam aliquam, ultricies mi in lectus porttitor enim. Ac ornare
            morbi ut ut et risus eleifend rhoncus. In neque ornare non habitant
            eget sem. Duis leo consectetur urna, gravida. Scelerisque et
            faucibus id proin. Ridiculus varius sit a id. Egestas nisi sapien
            lorem rutrum nullam sed. Ultricies a integer nulla placerat
            convallis ultricies sit. Amet dolor lorem vitae massa. Sagittis
            donec elementum sit convallis aliquet dolor, nam nunc. Massa gravida
            ornare vestibulum et proin habitant ut. Nibh magna etiam metus, vel,
            facilisi auctor. Lacinia nec, tellus augue vitae purus. Aliquam eget
            semper pharetra tortor. Consequat pharetra porttitor amet
            ullamcorper risus, adipiscing etiam. Non sed sit etiam nunc orci
            amet, libero mauris ac. Suscipit faucibus et nunc mauris posuere
            arcu sem. Pulvinar vel maecenas tellus lacus vel velit purus
            egestas. Ac nisi, placerat aliquam cras diam arcu magna. In quis
            habitant varius nec amet mattis laoreet eget lobortis. Gravida proin
            elit id lacus malesuada donec felis. Dolor varius eu eu lectus.<br/> Nunc
            eleifend id aliquet turpis laoreet dignissim sapien hendrerit ut.
            Pellentesque aliquam arcu pretium venenatis nisi id egestas lectus
            ultrices. Ullamcorper suspendisse non enim pretium. Sed diam non
            tincidunt elit. Fermentum blandit aenean laoreet vel curabitur ut
            curabitur orci. Urna ultrices aliquam eleifend facilisi non. In
            elementum risus elementum consequat. Quam cursus hendrerit posuere
            elementum sed quis tellus. Nisl, aliquet cursus iaculis turpis in
            eleifend at bibendum nunc.<br/> In a orci amet turpis nunc aenean risus
            eget. Bibendum blandit pellentesque fringilla sed nibh faucibus
            viverra mauris nam. Facilisi egestas pretium sed nulla gravida
            tortor. Vulputate senectus sagittis mi gravida nisi nec varius
            pretium porta. Elementum cursus molestie egestas amet vitae
            convallis sit ornare.<br/> Rhoncus eget nibh auctor viverra. Ac tortor
            mus amet urna, nulla. Egestas pellentesque nibh dictumst at et,
            vitae. Dignissim velit vitae ut tristique massa. Nulla lorem morbi
            ultricies viverra suspendisse. Turpis scelerisque mauris viverra et.
            A, risus lacus pellentesque leo quam volutpat. Urna tincidunt vitae
            pellentesque auctor quam placerat. Elit placerat aliquet ullamcorper
            orci, vel ac. Morbi vulputate non mauris semper ut consequat auctor.
          </Typography>
        </Box>
      </Container>
    </Box>
   <Footer/>
  </>
  );
}
