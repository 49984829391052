import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Toolbar,
  makeStyles,
  IconButton,
  Hidden,
  SvgIcon,
  InputBase,
  Box,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  TextField,
  Typography,
  Button,
} from "@material-ui/core";
import { Menu as MenuIcon } from "react-feather";
import ConfirmationDialog from "src/component/Confirmation";
import { Dialog } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { alpha } from "@material-ui/core/styles";
import TopBarData from "./TopBarData";
import { useHistory } from "react-router-dom";

import RightSideDrawer from "./RIghtSideDrawer";
import { IoNotificationsOutline } from "react-icons/io5";
import PerfectScrollbar from "react-perfect-scrollbar";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.secondary.main,
  },
  desktopDrawer1: {
    position: "absolute",
    right: 73,
    top: 0,
    width: "240px !important",
    padding: "12px",
    color: "#000",
    // background: theme.palette.primary.main,
  },
  toolbar: {
    padding: "0 10px",
    float: "right",
    width: "calc(100% - 256px)",
    right: 0,
    display: "flex",
    justifyContent: "space-between",

    position: "absolute",
    top: -2,
    padding: 0,
    background: "#fff",
    "@media (max-width: 1279px)": {
      width: "100%",
    
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
   
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    opacity: "0.8",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    border: "0.5px solid #DBD8FC",
    borderRadius: "10px",
    width: "400px !important",
    opacity: "0.9",
    boxShadow:'0px 100px 80px rgba(41, 72, 152, 0.05), 0px 64.8148px 46.8519px rgba(41, 72, 152, 0.037963), 0px 38.5185px 25.4815px rgba(41, 72, 152, 0.0303704), 0px 20px 13px rgba(41, 72, 152, 0.025), 0px 8.14815px 6.51852px rgba(41, 72, 152, 0.0196296), 0px 1.85185px 3.14815px rgba(41, 72, 152, 0.012037)',
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "61ch",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },

  FiterBox: {
    [theme.breakpoints.only("xs")]: {
      display: "none",
    },
  },
  icon: {
    fontSize: "25px",
    padding: "0px 20px",
    cursor:"pointer",
  },
  input: {
    borderRadius: "7px",
    background: "#fff",
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const history = useHistory();
  const [rightBar, setRightBar] = useState(false);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [state, setState] = React.useState({
    Deposite: "",
    name: "",
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };
  // const [age, setAge] = React.useState("");

  // const handleChange = (event) => {
  //   setAge(event.target.value);
  // };

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar
        options={{ suppressScrollX: true }}
        className="walletbox"
      >
        <Box className={classes.ProfileTitle}>
          <Typography variant="h6" align="left">
            Prince Sharma
          </Typography>
          <Typography variant="caption" style={{ color: "#000" }} align="left">
            0xx25dswdw...21dez
          </Typography>
        </Box>
        <div
          style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.11)", height: 10 }}
        ></div>
        <Box pt={1}>
          return (
          <Button
            fullWidth
            className={classes.btnHover}
            style={{
              justifyContent: "left",
              color: "#000",
              textTransform: "capitalize",
            }}
            // key={i}
            // onClick={() => {
            //   section.title === "Logout"
            //     ? setOpen(true)
            //     : history.push(section.href);
            // }}
          >
            {/* <Icon color="#000" /> FSGKSNFGKL */}
          </Button>
          );
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <AppBar
      elevation={0}
      className={clsx(classes.root, className)}
      color="inherit"
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            color="#fff"
            onClick={onMobileNavOpen}
            style={{ marginRight: 10, padding: "23px" }}
          >
            <SvgIcon fontSize="small">
              <MenuIcon style={{ color: "#197ab3" }} />
            </SvgIcon>
          </IconButton>
        </Hidden>
        {/* <TopBarData /> */}
        <Box style={{marginLeft:"4rem",}}>
          {/*<div className={classes.search}>
            <div className={classes.searchIcon}>
              <img src="images/SearchVector.png" style={{  width: "21px", height: "21px" }} />
            </div>
            <InputBase
              placeholder="Search Files..."
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
            />
            </div>*/}
            </Box>
        <Box style={{ display: "flex", alignItems: "center" , marginRight:"7rem",}}>
          <Box className={classes.FiterBox}>
            <Box style={{ display: "flex", alignItems: "center" }}>
            <Box >
                {/*<FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel
                    htmlFor="outlined-age-native-simple"
                    style={{ fontSize: "14px" }}
                  >
                    Filter
                  </InputLabel>
                  <Select
                    native
                    value={state.Filter}
                    onChange={handleChange}
                    label="Deposite"
                    InputProps={{
                      className: classes.input,
                      name: "Filter",
                      id: "outlined-age-native-simple",
                    }}
                  >
                    <option aria-label="None" value="" />
                    <option value={10}>Transaction Details</option>
                    <option value={20}>Deposit</option>
                    <option value={30}>Withdrow Details</option>
                  </Select>
                  </FormControl>*/}
              </Box>
              <Box>
                <IoNotificationsOutline
                  className={classes.icon}
                  onClick={() => history.push("/notification")}
                />
              </Box>
             <Dialog
                style={{ width: "200px !important" }}
                classes={{ paper: classes.desktopDrawer1 }}
                open={rightBar}
                onClose={() => {
                  setRightBar(false);
                }}
              >
                <Typography style={{ color: "#000" }}>Notification</Typography>
              </Dialog> 
            </Box>
          </Box>
          &nbsp;&nbsp;
          <RightSideDrawer />
              </Box>
      </Toolbar>

    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;
