import React, { useState, useEffect, useContext } from "react";
import "src/scss/main.css";
import {
  Box,
  Typography,
  TextField,
  Grid,
  makeStyles,
  Button,
  Link,
  FormHelperText,
} from "@material-ui/core";
import Logo from "src/component/Logo";
import { FaFacebook, FaGoogle } from "react-icons/fa";
import { useHistory, Link as RouterComponent } from "react-router-dom";
import { Form, Formik } from "formik";
import * as yep from "yup";
import { calculateTimeLeft } from "./timer";
import TopBar from "src/layouts/LoginLayout/index.js"

import Page from "src/component/Page";
import { toast } from "react-toastify";
import axios from "axios";
import ApiConfig from "src/connectors/config/ApiConfig.js";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import moment from "moment";
import { AuthContext } from "src/context/Auth";

const useStyles = makeStyles((theme) => ({
  logosec: {
    "@media(min-width:1280px)": {
      display: "none",
    },
  },

  forgot: {
    display: "flex",
    justifyContent: "flex-end",

  },

}));

function Login(props) {
  const history = useHistory();
  const auth = useContext(AuthContext);
  console.log("yi9huthfjfiog", auth);

  const [isSubmit, setIsSubmit] = useState(false);
  const classes = useStyles();

  const [isLoading, setLoader] = useState(false);
  const [isloading, setloader] = useState(false);

  const [sendOTP, setOtpSent] = useState();

  // const handleFormSubmit = async (values) => {
  //   console.log("values-----", values);
  //   try {
  //     setLoader(true);

  //     const res = await axios({
  //       method: "PATCH",
  //       url: ApiConfig.verifyOTPSMS,
  //       headers: {
  //         authorization: `Bearer ${window.localStorage.getItem("token")}`,
  //       },
  //       data: {
  //         code: parseInt(values.otp),
  //       },
  //       params: {
  //         email: window.localStorage.getItem("email"),
  //       },
  //     });
  //     if (res.data.status === 200) {
  //       toast.success("OTP has been verified.");
  //       setLoader(true);
  //       setIsSubmit(true);

  //       history.push({
  //         pathname: "/reset-password",
  //         state: { email: values.email, otp: values.otp },
  //       });
  //     } else {
  //       setLoader(false);
  //       toast.warn(res.data.message);
  //     }
  //   } catch (error) {
  //     setLoader(false);
  //     if (error.response) {
  //       toast.error(error.response.data.message);
  //     } else {
  //       toast.error(error.message);
  //     }
  //   }
  // };
  
  let VerifyOtp = (values) => {

     
    fetch("https://nodepune-cryptobot.mobiloitte.com/api/v1/user/verifyOTP", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      method: "PATCH",


      body: JSON.stringify({
        email: sessionStorage.getItem("email"),
        otp: values.otp
      }),
      
      // params: {
      //   email: window.localStorage.getItem("email"),
      // },
    })
      .then(function (response) {

        console.log(response);
        return response.json();
      })
      .then(function (data) {
        console.log(data.responseMessage);
        history.push ("/reset-password")
      });
  };


  let ResendRequest = () => {
   
    fetch("https://nodepune-cryptobot.mobiloitte.com/api/v1/user/resendOTP", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      method: "PATCH",


      body: JSON.stringify({
        email: sessionStorage.getItem("email"),

      })
    })
      .then(function (response) {

        // console.log(response);
        return response.json();
      })
      .then(function (data) {
        console.log("Resend", data.responseMessage);
      });
  };

 

  return (
    <>
      {calculateTimeLeft}
      <TopBar>
        <Page title="forgot">
          <Grid className="d-flex height100" style={{ paddingTop: "10rem" }}>
            <Box className="loginForm">
              <Box className="signupbox">
                <Grid container direction={"column"}>
                  <Grid
                    item
                    style={{ display: "flex", justifyContent: "left" }}
                  >
                    {/* <Logo
                width="110"
                style={{ padding: "15px 0", cursor: "pointer" }}
              /> */}
                    <Typography
                      variant="h5"
                      style={{ fontSize: "45px", color: "#000000" }}
                     >
                      Verification Code <br />{" "}
                      <span style={{ fontSize: "18px", color: "#343434" }}>
                        A 4- digit Code have been sent to your mail.
                      </span>
                    </Typography>
                  </Grid>{" "}
                  <Grid item>
                    <Box className={classes.logosec}>
                      <Logo width="110" style={{ cursor: "pointer" }} />
                    </Box>
                  </Grid>
                  <Grid item style={{ marginTop: "20px" }}></Grid>
                  <Formik
                    onSubmit={(values) => VerifyOtp(values) }
                    initialValues={{
                      otp: "",
                    }}
                    initialStatus={{
                      success: false,
                      successMsg: "",
                    }}
                    validationSchema={yep.object().shape({
                      otp: yep
                        .string()
                        .required("invalid OTP")
                        // .required("OTP is required")
                        .matches(/^[0-9]*$/, "Must be a valid OTP")
                        .max(4, "Should not exceeds 4 digits")
                        .min(4, "Must be only 4 digits"),
                    })}
                  >
                    {({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      touched,
                      values,
                      setFieldValue,
                    }) => (
                      <Form>
                        <Grid
                          item
                          style={{ marginBottom: "10px", marginTop: ".5rem" }}
                        >
                          {/* <Box mt={1} mb={1}>
                    <Typography
                        style={{ fontSize: "18px", color: "#343434" }}
                      >
                      Please enter OTP
                      </Typography>
                    </Box> */}

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "3rem",
                              marginLeft: "0%",
                            }}
                          >

                            <TextField
                              style={{
                                width: "25rem",
                                border: "1px solid #333",
                              }}
                              // label="Enter Your OTP"
                              // type="number"
                              variant="outlined"
                              size="small"
                              name="otp"
                              error={Boolean(touched.otp && errors.otp)}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.otp}
                              onKeyPress={(event) => {
                                if (event?.key === "-" || event?.key === "+") {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </div>

                          <FormHelperText
                            error
                            style={{
                              fontSize: "12px",
                              //marginLeft: "5rem"
                            }}
                          >
                            {touched.otp && errors.otp}
                          </FormHelperText>
                        </Grid>

                        <Grid>
                          <Box mt={6} className="d-flex justify-space-between">
                            <Button
                              variant="contained"
                              color="secondary"
                              style={{
                                width: "420px",
                                height: "60px",
                                marginTop: "10px",
                                padding: "5px",
                                background: `linear-gradient(180deg, #5D7FF4 0%, #406BF5 100%)`,
                                borderRadius: "7px",
                              }}
                              
                              type="submit"
                              disabled={isLoading}
                          //onClick={() => history.push("/reset-password")}
                            >
                              Submit
                             
                        {isLoading && <ButtonCircularProgress />}
                            </Button>
                            &nbsp;&nbsp;
                            {auth.timeLeft && auth.timeLeft.seconds > 0 ? (
                              <>
                                <Box display="flex" justifyContent="flex-end">
                                  <Typography
                                    variant="body1"
                                    style={{
                                      color: "red",
                                      fontSize: "12px",
                                      fontWeight: 500,
                                    }}
                                  >
                                    OTP will expire in {auth.timeLeft?.minutes}{" "}
                                    m : {auth.timeLeft?.seconds} s
                                  </Typography>{" "}
                                </Box>
                              </>
                            ) : (
                              <Box
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "1rem",
                                }}
                              >
                                <Typography
                                  style={{
                                    fontSize: "18px",
                                    color: "#343434",
                                    marginTop: "2%",
                                  }}
                                >
                                  Don't receive a OTP? &nbsp;
                                  <Link
                                    component={RouterComponent}
                                    to="/verifyotp"
                                    style={{
                                      textDecoration: "none",
                                      color: "blue",
                                      fontSize: "18px",
                                      marginTop: "2%",
                                    }}
                                    onClick={() => {
                                   
                                
                                      alert("OTP sent successfully")
                                    || ResendRequest()}}
                                    disabled={
                                      auth.timeLeft && auth.timeLeft.seconds > 0
                                    }
                                  >
                                    Resend OTP
                                  </Link>
                                </Typography>

                                {/* <Button
                          variant="contained"
                          color="secondary"
                          style={{ marginTop: "10px", padding: "10px" }}

                          onClick={() => {
                            resetotphandle();
                          }}
                          disabled={auth.timeLeft && auth.timeLeft.seconds > 0}
                        >
                          {" "}
                          Resend OTP{" "}
                        </Button> */}
                              </Box>
                            )}
                          </Box>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                  {/* <Grid item>
              <Box mt={3}>
                <Typography
                  variant="body1"
                  style={{ color: "#000000", fontSize: "15px" }}
                >
                  <Link
                    component={RouterComponent}
                    to="/login"
                    style={{ textDecoration: "none",color:"#000" }}
                  >
                    Back To Login
                  </Link>
                </Typography>
              </Box>
            </Grid> */}
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Page>
      </TopBar>
    </>
  );
}

export default Login;
