import React, { useEffect, useState, useContext } from "react";
import "src/scss/main.css";

import {
  Box,
  Typography,
  TextField,
  Grid,
  Button,
  Link,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  Input,
  InputAdornment,
  IconButton,
  makeStyles,
  MenuItem,
} from "@material-ui/core";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { BsEnvelope } from "react-icons/bs";
import { AiOutlineLock, AiOutlineEye } from "react-icons/ai";
import Checkbox from '@material-ui/core/Checkbox';
import { FaFacebook, FaGoogle } from "react-icons/fa";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Logo from "src/component/Logo";
import DatePicker from "react-datepicker";
import { Form, Formik } from "formik";
import * as yep from "yup";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import axios from "axios";
import ApiConfig from "src/config/APICongig";
import ReCAPTCHA from "react-google-recaptcha";
import { AuthContext } from "src/context/Auth";
import { patchResetPassword } from "src/services/ResetPasswordAPI";
// import DateFnsUtils from "@date-io/date-fns";
// import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import TopBar from "src/layouts/LoginLayout/index.js";
import { values } from "lodash";
import { useHistory, Link as RouterComponent } from "react-router-dom";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  buttonbox: {
    width: " 541px",
    height: " 60px",
    background: `linear-gradient(180deg, #5D7FF4 0%, #406BF5 100%)`,
    borderRadius: ' 7px',
    padding: "10px 18px",
    [theme.breakpoints.only("sm")]: {
      // maxWidth: "112px",
      fontSize: "14px",
    },
    [theme.breakpoints.only("xs")]: {
      // maxWidth: "112px",
      fontSize: "14px",
    },
  },
  title: {
    "& h3": {

      width: '637px',
      marginLeft: '-7%',
      fontWeight: "600",
      fontSize: "45px",
      fontFamily: "Inter",
      fontStyle: "normal",
      color: "#000000",
      lineHeight: "52px",
      "@media (max-width:767px)": {
        fontSize: "27px",
        lineHeight: "22px",
      },
      "@media (max-width:433px)": {
        fontSize: "27px",
        lineHeight: "28px",
      },
    },
  },
  date: {
    "& p": {
      marginLeft: "0px !important",
      fontSize: "12px !important",
    },
  },
  root: {
    width: "100%",
    backgroundColor: "#fff",
    "@media (min-width: 1326px)": {
      "& .MuiContainer-root": {
        paddingLeft: "0",
        paddingRight: "0"
      }
    },
  },
  logosec: {
    "@media(min-width:1280px)": {
      display: "none",
    },
  },
  formboxes: {
    marginTop: "0px",
  },
  newbox: {
    color: "rgba(53, 99, 246, 1) ",
    textDecoration: "none",
    "&:hover": { textDecoration: "underline" },

  },
}));
function LoginReset(props) {
  const classes = useStyles();

  // const formValidationSchema = yep.object().shape({
  //   email: yep
  //     .string()
  //     .email("You have entered an invalid email address. Please try again")
  //     .required("Please enter valid email.")
  //     .matches("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$"),

  //   password: yep
  //     .string()
  //     .required("Password is required")
  //     .matches(
  //       /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/
  //     ),
  //   confirmPassword: yep
  //     .string()
  //     .required("Confirm Password is required")
  //     .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/)
  //     .oneOf([yep.ref("password"), null], "Passwords must match"),
  // });


  // const [countryCode, setCountryCode] = useState("");
  const auth = useContext(AuthContext);

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  // const [phone, setPhone] = useState();
 
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [Countrylist, setCountrylist] = useState();
  const [showStates, setShowStates] = useState([]);
  const [done, setDone] = useState(false);
  const recaptchaRef = React.createRef();
  const [access, setAccess] = React.useState(true);

  const [btnText, setBtnText] = useState("CREATE AN ACCOUNT");



  // const formInitialSchema = {
  //   email: "",
  //   password: "",
  //   confirmPassword: "",
  // };
  // const handleFormSubmit = async (values) => {
  //   setIsLoading(true);
  //   //setBtnText("Creating....");
  //   console.log("values-----", values);
  //   const Data = {
  //     "email": values.email,
  //     "password": values.password,
  //     "confirmPassword": values.confirmPassword,
  //   }
    
  //   try {
  //     const res = await axios.post(ApiConfig.userSignUp, {
  //       email: localStorage.getItem("email"),
  //       password: values.password,
  //       confirmPassword: values.confirmPassword,
  //     });

  //     if (res.data.status === 200) {
  //       setIsLoading(false);
  //       setBtnText("CREATE AN ACCOUNT");
  //       toast.success("OTP sent successfully, Please check your email.");
  //       auth.setEndtime(moment().add(3, "m").unix());
  //       history.push("/verify-email-otp");
  //     } else if (res.data.status === 205) {
  //       toast.warn("Email Already Registered");
  //       setIsLoading(false);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setIsLoading(false);
  //     toast.error(error.message);
  //     setBtnText("CREATE AN ACCOUNT");
  //   }
  // };
  const [agree, setAgree] = useState(false);

  const checkboxHandler = () => {
    // if agree === true, it will be set to false
    // if agree === false, it will be set to true
    setAgree(!agree);
    // Don't miss the exclamation mark
  }


  useEffect(() => {
    // axios.get("/static/json/countries.json").then(function (response) {
    //   setCountries(response.data.countries);
    //   axios.get("/static/json/states.json").then(function (response) {
    //     setStates(response.data.states);
    //     axios.get("/static/json/cities.json").then(function (response) {
    //       setCities(response.data.cities);
    //     });
       
    //   });
  
    // });
    //getData();
   // ResetPassword();
  }, []);

  const getData = () => {
    const res = patchResetPassword();
    console.log("ResetPassword", res);
  }
  const changeStateList = (name) => {
    const selectted = states.filter((cont) => {
      return cont.name === name;
    });
    if (selectted.length !== 0) {
      const contId = selectted[0].id;
      // const allCity = cities.filter((city) => {
      //   return city.state_id === contId;
      // });
      // setShowCities(allCity);
    }
  };

  const changeState = (e) => {
    const name = e.target.value;
    changeStateList(name);
  };

  const changeCountryList = (name) => {
    const selectted = countries?.filter((cont) => {
      return cont.name === name;
    });
    const contId = selectted[0]?.id;

    const allState = states?.filter((state) => {
      return state.country_id === contId;
    });
    setShowStates(allState);
  };

  const changeCountry = (e) => {
    const name = e.target.value;
    changeCountryList(name);
  };

  const ResetPassword = (values) => {

    console.log("for values",values)
    
    fetch("https://nodepune-cryptobot.mobiloitte.com/api/v1/user/resetPassword", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      method: "PATCH",
      body: JSON.stringify({
        email: sessionStorage.getItem("email"),
        password: values.password,
        confirmPassword: values.confirmPassword,
      }),

      // params: {
      //   email: window.localStorage.getItem("email"),
      // },
    })
      .then(function (response) {

        //console.log(response);
        return response.json();
      })
      .then(function (data) {
        console.log("Reset", data.responseMessage);
        history.push("/dash");
      });
  };
  //ResetPassword();
  //console.log("formInitialSchema", formInitialSchema)
  return (
    <>
      <TopBar>
        <page>
          <Grid className="d-flex height100">
            <Box className="loginForm">
              <Box className="signupBox">
                <Box className="signupbox">
                 
                  <Formik
                    onSubmit={ResetPassword}
                    initialValues={{
                     email: "",
                    password: "",
                      confirmPassword: ""
                    }}
                    initialStatus={{
                      success: false,
                      successMsg: "",
                    }}
                    validationSchema={
                      yep.object().shape({
                         password: yep
                          .string()
                          .required("Password is required")
                          .matches(
                            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/
                          ),
                        confirmPassword: yep
                          .string()
                          .required("Confirm Password is required")
                          .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/)
                          .oneOf([yep.ref("password"), null], "Passwords must match"),
                      })
                    }
                  >
                    {({
                      errors,
                      handleBlur,
                      handleChange,
                      touched,
                      values,
                      setFieldValue,
                    }) => (
                      <Form>
                        <Grid container direction={"column"} style={{ marginTop: '16rem' }}>
                          <Grid item>
                            <Box className={classes.logosec}>
                              <Logo width="110" style={{ cursor: "pointer" }} />
                            </Box>
                          </Grid>

                          <Grid item style={{ marginTop: "7px" }}></Grid>

                          <Grid item>
                            <FormControl fullWidth>
                              <Box
                                style={{
                                  width: "100%",
                                  marginTop: "-9px",
                                  marginBottom: "17px",
                                }}
                               >
                                <Typography
                                  style={{ fontSize: "18px", color: "#343434", marginLeft: '-7%', marginTop: '3%' }}
                                >
                                 Enter new password
                                </Typography>
                                <TextField style={{ width: "543px", height: "60px", marginLeft: '-7%', marginTop: '2%' }}
                                  className={classes.inputvalue}
                                  placeholder="Enter Your Password"
                                  size="small"
                                  variant="outlined"
                                  autoComplete="new-password"
                                  fullWidth
                                  type={showPassword ? "text" : "password"}
                                  value={values.password}
                                  name="password"
                                  error={Boolean(
                                    touched.password && errors.password 
                                  )}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  InputProps={{
                                    className: classes.textfiled,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          onClick={() =>
                                            setShowPassword(!showPassword)
                                          }
                                          edge="end"
                                        >
                                          <Box className={classes.passsec}>
                                            {showPassword ? (
                                              <AiOutlineEye
                                                style={{
                                                  color: "#7A7A7A",
                                                  // color: "#7A7A7A",
                                                  fontSize: "18px",
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                }}
                                              />
                                            ) : (
                                              <AiOutlineEyeInvisible
                                                style={{
                                                  color: "#7A7A7A",
                                                  // color: "#7A7A7A",
                                                  fontSize: "18px",
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                }}
                                              />
                                            )}
                                          </Box>
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <Box
                                          style={{
                                            borderRight: "0.5px solid #7A7A7A",
                                          }}
                                        >
                                          <AiOutlineLock
                                            style={{
                                              marginLeft: '-2px',
                                              marginRight: "10px",
                                              width: '14px',
                                              color: "#3563F6",
                                              fontSize: "22px",

                                            }}
                                          />
                                        </Box>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                                {touched.password && errors.password && (
                                  <FormHelperText
                                    error
                                    style={{ fontSize: "12px",  }}
                                  >
                                    Mnimum one uppercase, One lower case, Special character, 8-20 Characters .
                                  </FormHelperText>
                                )}
                              </Box>
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            style={{ marginBottom: "10px", marginTop: "-17px" }}
                          >
                            <FormControl fullWidth>
                              <Box
                                style={{ width: "100%" }}
                                className={classes.loginForm1}
                              >
                                <Typography
                                  style={{ fontSize: "18px", color: "#343434", marginLeft: '-7%', marginTop: '3%' }}
                                >
                                  Confirm Password
                                </Typography>
                                <TextField style={{ width: "543px", height: "60px", marginLeft: '-7%', marginTop: '2%' }}
                                  placeholder="Enter Your Confirm Password"
                                  size="small"
                                  variant="outlined"
                                  fullWidth
                                  type={showPassword ? "text" : "password"}
                                  value={ values.confirmPassword}
                                  name="confirmPassword"
                                  // placeholder="Confirm your password"
                                  error={Boolean(
                                    touched.confirmPassword &&
                                    errors.confirmPassword
                                  )}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                  //onChange={handleSubmit}
                                  InputProps={{
                                    className: classes.textfiled,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          onClick={() =>
                                            setShowPassword1(!showPassword)
                                          }
                                          edge="end"
                                        >
                                          <Box className={classes.passsec}>
                                            {showPassword ? (
                                              <AiOutlineEye
                                                style={{
                                                  color: "#7A7A7A",
                                                  // color: "#7A7A7A",
                                                  fontSize: "18px",
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                }}
                                              />
                                            ) : (
                                              <AiOutlineEyeInvisible
                                                style={{
                                                  color: "#7A7A7A",
                                                  // color: "#7A7A7A",
                                                  fontSize: "18px",
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                }}
                                              />
                                            )}
                                          </Box>
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <Box
                                          style={{
                                            borderRight: "0.5px solid #7A7A7A",
                                          }}
                                        >
                                          <AiOutlineLock
                                            style={{
                                              marginLeft: '-2px',
                                              marginRight: "10px",
                                              width: '14px',
                                              color: "#3563F6",
                                              fontSize: "22px",

                                            }}
                                          />
                                        </Box>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                                <FormHelperText error>
                                  {touched.confirmPassword &&
                                    errors.confirmPassword}

                                </FormHelperText>
                              </Box>
                            </FormControl>
                          </Grid>

                       
                          {/* 
                    <Box style={{ width: "100%" }}>
                      <form
                        onSubmit={() => {
                          recaptchaRef.current.execute();
                        }}
                      >
                        <ReCAPTCHA
                          // ref={recaptchaRef}
                          checked={done}
                          // size="invisible"
                          // size="invisible"
                          // originsitekey 6Lc2nUIgAAAAAHhIawk-yJCvv4wIUcYZiE1gFlc3
                          // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                          sitekey="6Lc2nUIgAAAAAHhIawk-yJCvv4wIUcYZiE1gFlc3"
                          onChange={() => setDone(true)}
                        />
                      </form>
                    </Box> */}
                          <Grid item mt={1} style={{ marginTop: "14px", marginLeft: '-7%' }}>
                            <Button
                              variant="contained"
                              color="secondary"
                              type="submit"
                              className={classes.buttonbox}
                              disabled={isLoading}
                              //onClick={ ResetPassword()}
                            >
                              Confirm
                              {isLoading && <ButtonCircularProgress />}
                            </Button>
                          </Grid>

                        
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </Box>
              </Box>
            </Box>
          </Grid>
        </page>
      </TopBar>
    </>
  );
}

export default LoginReset;
