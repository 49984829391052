import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import LoginLayout from "src/layouts/LoginLayout";
import DashboardLayout from "src/layouts/DashboardLayout1";
import LoginLayout1 from "src/layouts/LoginLayout1";
import ForgetasswordLink from "src/views/Auth/forget-password-link";
import Login from "src/views/Auth/forget-password-link/OtpVerify";
import signup from "src/views/Auth/signup/signup.js";
import Index from "src/views/pages/Home/Privacypolicy.js";
import LoginReset from "src/views/Auth/reset-password/index.js";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },

  {
    exact: true,
    path: "/dash",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/dash")),


  },
  {
    exact: true,
    path: "/warrior",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Homepage")),


  },
  {
    exact: true,
    path: "/exchange",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Exchange/index")),


  },
  {
    exact: true,
    path: "/exchang",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Exchange")),


  },
  {
    exact: true,
    path: "/pricing",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Cryptocurrencies")),


  },
  {
    exact: true,
    path: "/learn",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Cards")),
  },
  {
    exact: true,
    path: "/team",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Founder")),
  },



  {
    exact: true,
    path: "/straking",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Straking")),

  },
  {
    exact: true,
    path: "/view-profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ProfileComp/Profile")),

  },
  {
    exact: true,
    path: "/ProfileSave",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ProfileComp/ProfileSave")),

  },
  {
    exact: true,
    path: "/change-profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ProfileComp/SetPassword")),

  },
  {
    exact: true,
    path: "/WalletBank",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/Wallet/MyWallet")),

  },


  {
    exact: true,

    path: "/profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Profile")),


  },
  {
    exact: true,

    path: "/direct",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Direct")),


  },
  {
    exact: true,

    path: "/Transaction",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/TransactionHistory")),


  },
  {
    exact: true,

    path: "/triangular",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Triangular")),


  },
  {
    exact: true,

    path: "/loop",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Loop")),


  },
  {
    exact: true,

    path: "/intraexchange",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Intraexchange")),


  },
  {
    exact: true,

    path: "/inter",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Inter")),


  },
  {
    exact: true,

    path: "/notification",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Home/Notification")),


  },
  {
    exact: true,

    path: "/login",
    layout: LoginLayout,
    component: lazy(() => import("src/views/Auth/LogIn")),
  },
  {
    exact: true,

    path: "/register",
    layout: signup,
    component: lazy(() => import("src/views/Auth/signup/signup.js")),
  },

  {
    exact: true,
    path: "/forgot",
    layout: LoginLayout,
    component: lazy(() => import("src/views/Auth/forget-password/index")),
  },
  {
    exact: true,
    path: "/emailsent",
    layout: ForgetasswordLink,
    component: lazy(() => import("src/views/Auth/forget-password-link/index")),
  },
  {
    exact: true,
    path: "/verifyotp",
    layout: Login,
    component: lazy(() => import("src/views/Auth/forget-password-link/OtpVerify")),
  },
  {
    exact: true,
    path: "/term",
    layout: LoginLayout1,
    component: lazy(() => import("src/views/pages/Home/Terms-and-condition/Index")),
  },
  {
    exact: true,
    path: "/policy",
    layout: Index,
    component: lazy(() => import("src/views/pages/Home/Privacypolicy.js")),
  },
  {
    exact: true,
    path: "/reset-password",
    layout: LoginReset,
    component: lazy(() => import("src/views/Auth/reset-password/index.js")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];

